import React from 'react';
import Diaporama from '../components/Diaporama';
import Redirection from '../components/Redirection';
import Footer from '../components/Footer';
import '../styles/AppAgt.css';

const Accueil = () => {
    return (
        
        <div className='AppAgt'>
            <Diaporama />
            
            <div className='LigneTexte'>
            
                <h1 className='LigneTexte LigneTexteT2'> Site de l'Amicale la Gare de Trôo</h1>
                
                
                
                
                <Redirection /> 
            </div>

            <Footer />  
        </div>
    );
};

export default Accueil;