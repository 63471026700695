import React from 'react';
import '../../styles/BoutiqueDetail.css';
import Ardoises from '../../images/Ardoises.jpg';
import MielFleurs from '../../images/Miel3Fleurs.jpg';
import MielRoch from '../../images/MielRoch.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const DiversDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Ardoises</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <img className='ImagBtqDetail' src={Ardoises} alt="Gare de Troo" />
                <div className='Mag'></div>
                <h3 className='H4BtqDetail'>Photographies TTVL sur ardoises créées par
                <a className='LnkBtq' href="https://studiocentre41.jimdofree.com/" target="_blank"> Studio centre 41</a>
                </h3>
            </div>
            <div className='BtqDetail ColonBtqDetail'>
                <div className='BtqDetail Mag'>
                    <h2 className='H2BtqDetail'> Miel ADN41</h2>
                </div>
                <h3 className='H4BtqDetail'>Miel de fleurs de printemps. Pôt de 500grs</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={MielFleurs} alt="Miel Fleur" />
                </div>
                <h3 className='H4BtqDetail'>Miel récolté au châteu de Rochambeau. Pôt de 250grs</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={MielRoch} alt="Miel Fleur" />
                </div>
            </div>
        
            <div className='Mag'>
            <Footer />
            </div>    
            
            
        </div>
    );
};

export default DiversDetail;