import React from 'react';
import '../styles/Horaires.css';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../components/EntetePageHoraires';
import Fleche from '../images/Fleche.png';

function Horaires ()  {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
            <div>
            <EntetePageHoraires />
            <h2 className='LigneH2t' > - </h2>
            <div>
            <table className='HoTbl' align='center' >
            <tbody>
                <tr className='HoTbl'>
                    <td className='HoTbl'><Button className='BtnHor2' onClick={() => navigate('/Avril')}>Avril</Button>
                        <a href="/Avril"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    
                   {/*  <td className='HoTbllit'>                 
                    
                    </td>
                    <td className='HoTbllit'> </td> */}
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> 
                    <a href="/Mai"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td className='HoTbllit'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </td> */}
                
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Juin')}>Juin</Button> 
                    <a href="/Juin"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>
                 */}
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Juillet')}>Juillet</Button> 
                    <a href="/Juillet"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td> */}
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Aout')}>Aout</Button> 
                    <a href="/Aout"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td> */}
                
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Septembre')}>Septembre</Button> 
                    <a href="/Septembre"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td> */}
                
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Octobre')}>Octobre</Button> 
                    <a href="/Octobre"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td> */}
                
                </tr>
                <tr>
                    <td className='HoTbl'><Button className='BtnHor' onClick={() => navigate('/Novembre')}>Novembre</Button> 
                    <a href="/Novembre"> <img src={Fleche} className='HoFl' alt='fleche' /> </a>
                    </td>
                    {/* <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td> */}
                
                </tr>
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2> 
            </div>
            <Footer />
            </div>
        </div>
    );
};

export default Horaires;